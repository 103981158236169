import { MapBanner } from "./components/mapBanner";
import PageHeading from "../../components/__simple/_pageheading";
import { Fragment, useEffect, useState } from "react";
import ContactTile from "./components/contactTile";
import { InputField } from "../../components/_input/_input";
import { TextArea } from "../../components/_input/_textArea";
import { FormButton } from "../../components/_input/_button";
import Breadcrumb from "../../components/__simple/breadcrumb";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { changeHeader, changeIcons } from "../../_redux/slices/navSlice";
import axios from "axios";
import { FilterButton } from "../../components/__simple/_filterButton";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { GetMessages, formValidate, validateProperty } from '../../utils/validation';
import config from '../../configuration/config.json';
import PhoneDropDown from "../../components/_input/_phoneDropDown";
import { toast } from "react-toastify";
import { DropDownInput } from "../../components/_input/_dropdown";
import { Helmet } from "react-helmet";
export function ContactPage() {
    const { t: trans, i18n } = useTranslation(["common", "form", "messages", "inputs"]);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [paramSubject, setParamSubject] = useState(query.get("subject") ?? null);

    const dispatch = useDispatch();
    let lang = (i18n.language || 'ar');
    const languageDir = 'language-' + lang;
    const [page, setPage] = useState({});
    const [branches, setBranches] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [message, setMessage] = useState({
        fullName: '',
        phone: '',
        email: '',
        subject: paramSubject,
        messageContent: ''
    });

    const schema = Joi.object({
        fullName: Joi.string().required().messages(GetMessages()),
        phone: Joi.string().min(12).required().messages(GetMessages()),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: false } }).required().messages(GetMessages()),
        subject: Joi.string().required().messages(GetMessages()),
        messageContent: Joi.string().required().messages(GetMessages())
    });

    const [error, setError] = useState({});

    const submitRequest = async (e) => {
        e.preventDefault();
        const result = formValidate(schema, message) ?? {};
        setError(result);
        try {
            if (Object.keys(result).length === 0) {
                console.log(Object.keys(error).length);
                const response = await axios.post(
                    config.baseURL + 'add',
                    {
                        "object": "siteContactMessages",
                        "values": {
                            "name": message.fullName,
                            "email": message.email,
                            "phoneNumber": message.phone,
                            "subject": Number(message.subject),
                            "message": message.messageContent,
                        }
                    }
                );
                if (response.status === 200 || response.status === 201) {
                    const defaultValue = { ...message };
                    setMessage({
                        fullName: '',
                        phone: defaultValue.phone,
                        email: '',
                        subject: '',
                        messageContent: ''
                    });
                    setError({});
                    toast.success(trans("messages:message_sent_successfully"));
                }
            }
        } catch (e) {

        }

    }
    useEffect(async () => {

        try {

            const response = await axios.request({
                baseURL: config.controllURL,
                url: lang + "/api/contact/contact/",
                method: "get",
            });

            setPage(response.data.page);
            setBranches(response.data.branches);
            setContacts(response.data.contacts);
            setSubjects(response.data.subjects.map(item => { return { text: item.description, value: item.id } }));

        } catch (error) {
            console.log(error);
        }
        dispatch(
            changeIcons({ iconsBlack: true })
        )
        return () => {
            dispatch(
                changeIcons({ iconsBlack: false })
            )
        }
    }, []
    );
    useEffect(() => {
        if (branches.length > 0)
            setSelectedBranch(branches[0].name)
    }, []);


    const [seoPageInfo, setSeoPageInfo] = useState({});
    useEffect(async () => {

        var page = "contact"


        const response = await axios.post(
            config.baseURL + 'func',
            {
                "Name": "SiteFetchSeoPages",
                "Values": {
                    "page": page
                }
            }
        );
        if (response.status == 200) {

            var resdata = response.data[0];

            setSeoPageInfo(resdata);


        }
    }, []);
    return (<Fragment>


        {
            seoPageInfo &&

            <Helmet>

                <meta charSet="utf-8" />
                {
                    seoPageInfo?.title &&
                    <title>{seoPageInfo?.title}</title>
                }
                {
                    seoPageInfo?.link &&
                    <link rel="canonical" href={seoPageInfo?.link} />

                }
                {
                    seoPageInfo?.MetaDescription &&
                    <meta name="description" content={seoPageInfo?.MetaDescription} />
                }
                {
                    seoPageInfo?.MetaGroups &&
                    <meta name="keywords" content={seoPageInfo?.MetaGroups} />
                }

            </Helmet>


        }
        <div className={"mb-20  mt-xs"}>
            <MapBanner contacts={contacts} branches={branches} activebranche={selectedBranch} />
        </div>
        <div className="container">
            <Breadcrumb
                links={[
                    {
                        name: trans("common:contact-us")
                    }
                ]}
            />
        </div>
        <div className={"container mb-32 grid-2-cols " + languageDir}>

            <div className={"mb-12"}>

                <PageHeading
                    title={page?.name}
                    subtitle={page?.subName}
                    text={page?.content}
                />

                <div>
                    <div className="contact-filter mb-12">
                        {
                            branches?.map((branch, index) => {
                                return (
                                    <FilterButton
                                        key={index}
                                        name={branch.content}
                                        isActive={branch.name == selectedBranch?.name}
                                        onclick={() => setSelectedBranch(branch)}
                                    />
                                );
                            })
                        }
                    </div>

                    <div className="contact-filter-list">
                        {
                            contacts?.map((contact, index) => {
                                if (contact.branchId == selectedBranch?.name)
                                    return (
                                        <ContactTile
                                            key={index}
                                            icon={contact.typeIconPath}
                                            value={contact.contactValue}
                                            type={contact.typeValue}
                                            name={contact.contactTitle ?? contact.typeTitle}
                                        />
                                    );
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={"contact-form  flex flex-row grid-2-cols nice-gap-2rem"}>
                <form
                    className={"contact-form  flex flex-row grid-2-cols nice-gap-2rem"}
                    onSubmit={
                        (e) => {
                            submitRequest(e);
                        }
                    }

                >
                    <InputField
                        placeholder={trans("form:name")}
                        name='fullName'
                        value={message.fullName}
                        errorLabel={error?.fullName}
                        onChangeValue={
                            (e) => {
                                const val = { ...message };
                                val.fullName = e.target.value;
                                setMessage(val);
                            }
                        }
                    />
                    <PhoneDropDown

                        errorLabel={error?.phone}
                        onChangeValue={(countryCode, phoneNumber) => {
                            const val = { ...message };
                            val.phone = `+${countryCode}${phoneNumber}`;
                            setMessage(val);
                        }}
                    />
                    <InputField
                        placeholder={trans("form:email")}
                        name='email'
                        value={message.email}
                        errorLabel={error?.email}
                        onChangeValue={
                            (e) => {
                                const val = { ...message };
                                val.email = e.target.value;
                                setMessage(val);
                            }
                        }
                    />
                    <DropDownInput
                        onSelect={(e) => {
                            const val = { ...message };
                            val.subject = String(e);
                            setMessage(val);
                        }}

                        selectedValue={message.subject}
                        name='subject'
                        className={"flex-grow-1"}
                        defaultOption={{ text: trans("form:subject"), value: "0" }}
                        options={[...subjects]}
                        errorLabel={error?.subject}
                    />
                    <TextArea
                        value={message.messageContent}
                        placeholder={trans("form:message")}
                        name='messageContent'
                        errorLabel={error?.messageContent}
                        onChangeValue={
                            (e) => {
                                console.log(e);
                                const val = { ...message };
                                val.messageContent = e;
                                setMessage(val);
                            }
                        }
                        row={3}

                    />
                    <FormButton
                        // isLoading={isLoading}
                        isPrimary={true}
                        classes={" px-10 py-5 "}
                        name={trans("form:send")}
                    />
                </form>
            </div>
        </div>
    </Fragment>

    )
}

