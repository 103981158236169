import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ErrorLabel from "../error";
import axios from "axios";
import config from '../../configuration/config.json';

const PhoneDropDown = ({
    mandotory = false,
    title = '',
    onChangeValue,
    errorLabel,
    defaultValue = '',
    defaultFlag = null,
    maxLength = 9,
    disabled = false,
    extraClasses = '',
    moduleLabel,
    countriesListCode,
    placeholderProp  ,
    isLoginProp = false
}) => {
    const countriesCode = [
        { code: '966', DescriptionAr: 'السعودية', DescriptionEn: 'Saudi Arabia', flag: 'saudiFlag.png' },
        { code: '49', DescriptionAr: 'المانيا', DescriptionEn: 'Germany', flag: 'germanyFlag.png' },
        { code: '55', DescriptionAr: 'البرازيل', DescriptionEn: 'Brazil', flag: 'brazilFlag.png' }
    ];

    const defaultFlagPath = '/assets/flags/';
    const { t: trans, i18n } = useTranslation(["form", "model", "new"]);
    const [isOpened, setIsOpened] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [placeholder, setPlaceholder] = useState(placeholderProp ? placeholderProp : trans("form:5xx xxx xxx"));
    const [currentcountriesCode, setCurrentcountriesCode] = useState(countriesCode);
    const [activeFlag, setActiveFlag] = useState(defaultFlag ? {
        flagText: defaultFlag,
        flag: defaultFlagPath + 'saudiFlag.png'
    } : {
        flagText: '966',
        flag: defaultFlagPath + 'saudiFlag.png'
    });
    const [phoneValue, setPhoneValue] = useState(defaultValue);
    const flagRef = useRef();
    const inputRef = useRef();
    let lang = (i18n.language || 'ar');
    let languageDir = "language-" + lang;

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [flagRef, isOpened]);

    const [Country, setCountry] = useState([]);

    async function FillAllCountries() {

        const Body = { "name": "site_AllCountries" }
        await axios.request({
            url: config.baseURL + 'func',
            data: Body, method: "POST", responseType: "json"
        })
            .then(function (response) {
                // console.log(response.data);
                if (response?.data && response.data.length > 0)
                    setCountry(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleChangeCountryCodeStatus = () => {
        if (disabled == false) {
            const newStatus = !isOpened;
            setIsOpened(newStatus);
        }

    };
    const handleClickOutside = (event) => {
        if (flagRef.current && !flagRef.current.contains(event.target) && isOpened === true) {
            setIsOpened(false);
            setIsFocus(false);
        }
    };

    useEffect(() => {
        if (defaultValue)
            setPhoneValue(defaultValue);
    }, [defaultValue]);


    useEffect(() => {
        FillAllCountries();
    }, []);
    useEffect(() => {

        if (Country && Country?.length > 0) {

            var bb = Country.map(a => {
                return (a?.DialingCode && a?.DialingCode != "001") && {
                    code: parseInt(a?.DialingCode, 10)?.toString(),
                    DescriptionAr: a?.DescriptionAr,
                    DescriptionEn: a?.DescriptionEn,
                    flag: ""
                }


            })

            setCurrentcountriesCode(bb);
        }
    }, [Country]);
    const handleChangeCountryCode = (item) => {
        let { code, flag } = item;
        setActiveFlag({
            flagText: code,
            flag: defaultFlagPath + flag
        });
        setIsOpened(false);
        onChangeValue(code, phoneValue);
        inputRef.current.focus();
    };
    const handleInputChange = (event) => {
        if (event.target.validity.valid) {
            setPhoneValue(event.target.value);
            if (onChangeValue) {
                onChangeValue(activeFlag.flagText, event.target.value);
            }
        }
    };
    return (
        <div className={"input " +extraClasses+" " + (isLoginProp == true ? '' : languageDir)}>
            {/* <label className={"input-label"}>{trans("form:phone")}</label> */}

            <div className="selectable-drop">
                <div className={`phone-selectable phone-selectable-bg-white ${disabled && " bg-gray-light "} ${isFocus ? 'active-border' : ''}`}>
                    <div className="phone-selectable-country-box" onClick={handleChangeCountryCodeStatus}>
                        <div className="nice-flex flex-row justify-center items-center">
                            <div className='nice-flex'>
                                <div
                                    className="phone-selectable-flex phone-selectable-flex-rows items-center text-left">
                                    <span className="mx-1 text-16 text-left text-bold">
                                        {'+(' + activeFlag.flagText + ')'}
                                    </span>
                                </div>
                            </div>

                            <div className={`text-inline-block flag-btn flag-btn-${isOpened ? 'open' : 'close'}`}>
                                <img src="/assets/icons/arrow.svg" alt="arrow" />
                            </div>
                        </div>

                    </div>
                    <input dir="ltr" disabled={disabled} maxLength={maxLength} pattern="[0-9]*" onFocus={() => {
                        setIsFocus(true)
                    }} onBlur={() => {
                        setIsFocus(false)
                    }}
                        value={phoneValue}
                        onInput={(e) => {
                            handleInputChange(e)
                        }} type="phoneNumber" ref={inputRef} className={(isLoginProp == false
                            ? "phone-selectable-input text-16 text-right  "
                            : "phone-selectable-input-from-login text-16 text-left ")+ (disabled && " bg-gray-light ")}
                        style={{ right: (lang == 'en' ? '-9rem' : 'auto') }}
                        placeholder={`${placeholder} ${mandotory ? '*' : ''} `} />

                    {
                        moduleLabel &&
                        <label htmlFor="input-field" className={'Input-module__label ' + (placeholder ? " input-has-placeholder " : "")}>{moduleLabel}</label>
                    }
                </div>
                {
                    isOpened && (<div className="phone-selectable-country-box-list" ref={flagRef}>
                        <ul className="list-unstyle">
                            {
                                currentcountriesCode?.map((item, index) => {

                                    return item?.code != null && item?.code != undefined ?
                                        <li className="phone-selectable-country-box-list-item p-3" key={index}
                                            onClick={() => {
                                                handleChangeCountryCode(item);
                                            }}>
                                            <span className="mx-2 text-16 text-left">
                                                {item[`${trans("model:description")}`]}
                                            </span>
                                            <div className="text-left">
                                                <span className="text-inline-block mx-2 text-16">
                                                    {'+(' + (item.code) + ')'}
                                                </span>
                                            </div>
                                        </li>
                                        : null
                                })
                            }
                        </ul>
                    </div>)
                }
            </div>
            <ErrorLabel errorTitle={errorLabel} />
        </div>

    );
};
export default PhoneDropDown;
